<template>
  <div id="DistributionMap">
    <div class="title">Distribution Map</div>
    <iframe :src="src" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      src: "",
    };
  },
  mounted() {
    this.src = "http://18.162.145.100/DistributionMap.html";
  },
};
</script>

<style>
#DistributionMap {
  height: 900.8px;
  width: 1000px;
  border-radius: 20px;
  padding: 20px;
  background: #023037d7;
  box-shadow: 0 2px 5px 2px #023037;
  text-shadow: 2px 2px #023037;

  margin-top: 10px;
  margin-bottom: 100px;
}
.title {
  text-shadow: 2px 2px #00151a;
  color: #f0c735;
  font-size: 28px;
}
iframe {
  width: 100%;
  height: 100%;
}
</style>
