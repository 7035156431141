<template>
  <div class="PricePredict">
    <el-row>
      <el-col :span="24"
        ><div class="grid-content bg-purple-dark">
          <div class="title">Beijing Rent Predict</div>
          <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="Area">
              <el-input class="input" v-model="form.area"></el-input
              ><span class="house-type-span"> square</span>
            </el-form-item>
            <el-form-item label="Floor">
              <el-input class="input" v-model="form.floor"></el-input>
            </el-form-item>
            <el-form-item label="Metro Distance">
              <el-slider
                class="slider"
                v-model="form.metroDistance"
                :max="2000"
                show-input
              ></el-slider>
            </el-form-item>
            <el-form-item label="House Type">
              <span class="house-type-span">bedroom</span>
              <el-input class="input" v-model="form.bedroom"></el-input
              ><span class="house-type-span"> living room</span>
              <el-input class="input" v-model="form.livingRoom"></el-input>
              <span class="house-type-span"> bathroom </span>
              <el-input class="input" v-model="form.bathroom"></el-input>
            </el-form-item>
            <el-form-item label="District">
              <el-select
                v-model="form.district"
                placeholder="please choose district"
              >
                <el-option
                  label="Fengtai District"
                  value="Fengtai District"
                ></el-option>
                <el-option
                  label="Changping District"
                  value="Changping District"
                ></el-option>
                <el-option
                  label="Chaoyang District"
                  value="Chaoyang District"
                ></el-option>
                <el-option
                  label="Daxing District"
                  value="Daxing District"
                ></el-option>
                <el-option
                  label="Dongcheng District"
                  value="Dongcheng District"
                ></el-option>
                <el-option
                  label="Fangshan District"
                  value="Fangshan District"
                ></el-option>
                <el-option
                  label="Haidian District"
                  value="Haidian District"
                ></el-option>
                <el-option
                  label="Huairou District"
                  value="Huairou District"
                ></el-option>
                <el-option
                  label="Mentougou District"
                  value="Mentougou District"
                ></el-option>
                <el-option
                  label="Miyun District"
                  value="Miyun District"
                ></el-option>
                <el-option
                  label="Pinggu District"
                  value="Pinggu District"
                ></el-option>
                <el-option
                  label="Shijingshan District"
                  value="Shijingshan District"
                ></el-option>
                <el-option
                  label="Shunyi District"
                  value="Shunyi District"
                ></el-option>
                <el-option
                  label="Tongzhou District"
                  value="Tongzhou District"
                ></el-option>
                <el-option
                  label="Xicheng District"
                  value="Xicheng District"
                ></el-option>
                <el-option
                  label="Yanqing District"
                  value="Yanqing District"
                ></el-option>
                <el-option
                  label="Yizhuang District"
                  value="Yizhuang District"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Elevator">
              <el-switch v-model="form.elevator"></el-switch>
            </el-form-item>
            <el-form-item label="Fuel Gas">
              <el-switch v-model="form.fuelGas"></el-switch>
            </el-form-item>
            <el-form-item label="Indoor Facilities">
              <el-checkbox-group v-model="form.indoorFacilities">
                <span class="icon iconfont">&#xe725;</span>
                <el-checkbox
                  label="Washing Machine"
                  name="indoorFacilities"
                ></el-checkbox>
                <span class="icon iconfont">&#xe724;</span>
                <el-checkbox
                  label="Air Conditioner"
                  name="indoorFacilities"
                ></el-checkbox>
                <span class="icon iconfont">&#xea70;</span>
                <el-checkbox
                  label="Wardrobe"
                  name="indoorFacilities"
                ></el-checkbox>
                <span class="icon iconfont">&#xe7fa;</span>
                <el-checkbox
                  label="Television"
                  name="indoorFacilities"
                ></el-checkbox>
                <span class="icon iconfont">&#xe698;</span>
                <el-checkbox
                  label="Refrigerator"
                  name="indoorFacilities"
                ></el-checkbox>
                <span class="icon iconfont water-heater">&#xe73f;</span>
                <el-checkbox
                  label="Water Heater"
                  name="indoorFacilities"
                ></el-checkbox>
                <span class="icon iconfont">&#xeb66;</span>
                <el-checkbox label="Bed" name="indoorFacilities"> </el-checkbox>
                <span class="icon iconfont">&#xe608;</span>
                <el-checkbox
                  label="Heating"
                  name="indoorFacilities"
                ></el-checkbox>
                <span class="icon iconfont">&#xe6a2;</span>
                <el-checkbox
                  label="Broadband"
                  name="indoorFacilities"
                ></el-checkbox>
                <span class="icon iconfont">&#xe60e;</span>
                <el-checkbox
                  label="Natural Gas"
                  name="indoorFacilities"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="Electricity">
              <el-radio-group v-model="form.electricity">
                <el-radio label="Commerce Electricity"></el-radio>
                <el-radio label="Civilian Electricity"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Water">
              <el-radio-group v-model="form.water">
                <el-radio label="Commerce Water"></el-radio>
                <el-radio label="Civilian Water"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-button type="info" @click="onSubmit">submit</el-button>
              <el-button @click="claerInfo">clear</el-button>
            </el-form-item>
          </el-form>
        </div></el-col
      >
    </el-row>
  </div>
</template>

<script>
export default {
  name: "PricePredict",
  props: {
    // msg: String,
  },
  data() {
    return {
      form: {
        area: "",
        floor: "",
        metroDistance: 0,
        bedroom: "",
        livingRoom: "",
        bathroom: "",
        district: "",
        elevator: false,
        fuelGas: false,
        electricity: "",
        water: "",
        indoorFacilities: [],
      },
      price: "",
    };
  },
  methods: {
    onSubmit() {
      if (
        !this.form.area ||
        !this.form.floor ||
        !this.form.metroDistance ||
        !this.form.bedroom ||
        !this.form.livingRoom ||
        !this.form.bathroom ||
        !this.form.district ||
        !this.form.water ||
        !this.form.electricity
      ) {
        this.$message({
          showClose: true,
          message: "Please fill out the form.",
        });
      } else {
        this.price = Math.floor(
          this.predictPrice(
            parseInt(this.form.area),
            this.form.electricity === "Commerce Electricity" ? 0 : 1,
            this.form.elevator ? 1 : 0,
            parseInt(this.form.floor),
            parseInt(this.form.metroDistance),
            this.form.fuelGas ? 1 : 0,
            this.form.water === "Commerce Water" ? 0 : 1,
            parseInt(this.form.bedroom),
            parseInt(this.form.livingRoom),
            parseInt(this.form.bathroom),
            this.form.indoorFacilities.includes("Washing Machine") ? 1 : 0,
            this.form.indoorFacilities.includes("Air Conditioner") ? 1 : 0,
            this.form.indoorFacilities.includes("Wardrobe") ? 1 : 0,
            this.form.indoorFacilities.includes("Television") ? 1 : 0,
            this.form.indoorFacilities.includes("Refrigerator") ? 1 : 0,
            this.form.indoorFacilities.includes("Water Heater") ? 1 : 0,
            this.form.indoorFacilities.includes("Bed") ? 1 : 0,
            this.form.indoorFacilities.includes("Heating") ? 1 : 0,
            this.form.indoorFacilities.includes("Broadband") ? 1 : 0,
            this.form.indoorFacilities.includes("Natural Gas") ? 1 : 0,
            this.form.district
          )
        );
        this.$alert(this.price, "Predict Price", {
          confirmButtonText: "Close",
          callback: () => {},
        });
      }
    },
    claerInfo() {
      (this.form.area = ""),
        (this.form.floor = ""),
        (this.form.metroDistance = ""),
        (this.form.bedroom = ""),
        (this.form.livingRoom = ""),
        (this.form.bathroom = ""),
        (this.form.district = ""),
        (this.form.elevator = false),
        (this.form.fuelGas = false),
        (this.form.electricity = ""),
        (this.form.water = ""),
        (this.form.indoorFacilities = []);
    },
    predictPrice(
      area,
      electricity,
      elevator,
      floor,
      metroDistance,
      fuel_gas,
      water,
      bedroom,
      living_room,
      bathroom,
      washing_machine,
      air_conditioner,
      wardrobe,
      television,
      refrigerator,
      water_heater,
      bed,
      heating,
      broadband,
      natural_gas,
      district
    ) {
      const coefficients = {
        intercept: -2819.8428797033107,
        area: 97.609599063054,
        electricity: 135.242346,
        elevator: 78.518876,
        floor: 10.518566416775409,
        metroDistance: -1.1202495680388966,
        fuel_gas: 145.242987,
        water: 257.7184688732248,
        bedroom: 79.017383,
        "living room": 514.35985,
        bathroom: 82.373385,
        "Washing Machine": -84.367002,
        "Air Conditioner": -71.662535,
        Wardrobe: -125.300425,
        Television: 182.750708,
        Refrigerator: 146.004075,
        "Water Heater": -99.820121,
        Bed: -46.602452,
        Heating: 167.745168,
        Broadband: 55.104968,
        "Natural Gas": 144.230935,
        "district_Fengtai District": 1829.8077839947396,
        "district_Changping District": -207.8777575362863,
        "district_Chaoyang District": 3558.1771714939046,
        "district_Daxing District": -928.6568530605455,
        "district_Dongcheng District": 5107.024803350518,
        "district_Fangshan District": -1585.832115815754,
        "district_Haidian District": 4748.718626444213,
        "district_Huairou District": -2491.076900694601,
        "district_Mentougou District": -361.59005097853657,
        "district_Miyun District": -4327.796768954464,
        "district_Pinggu District": -9446.099261949035,
        "district_Shijingshan District": 1917.386083931135,
        "district_Shunyi District": -559.7004271043605,
        "district_Tongzhou District": -556.7993305285687,
        "district_Xicheng District": 6356.030781223551,
        "district_Yanqing District": -2987.6755864149764,
        "district_Yizhuang District": -64.04019763109592,
      };

      let price = coefficients["intercept"];
      price += coefficients["area"] * area;
      price += coefficients["electricity"] * electricity;
      price += coefficients["elevator"] * elevator;
      price += coefficients["floor"] * floor;
      price += coefficients["metroDistance"] * metroDistance;
      price += coefficients["fuel_gas"] * fuel_gas;
      price += coefficients["water"] * water;
      price += coefficients["bedroom"] * bedroom;
      price += coefficients["living room"] * living_room;
      price += coefficients["bathroom"] * bathroom;
      price += coefficients["Washing Machine"] * washing_machine;
      price += coefficients["Air Conditioner"] * air_conditioner;
      price += coefficients["Wardrobe"] * wardrobe;
      price += coefficients["Television"] * television;
      price += coefficients["Refrigerator"] * refrigerator;
      price += coefficients["Water Heater"] * water_heater;
      price += coefficients["Bed"] * bed;
      price += coefficients["Heating"] * heating;
      price += coefficients["Broadband"] * broadband;
      price += coefficients["Natural Gas"] * natural_gas;

      const districtCoefficient = coefficients[`district_${district}`] || 0;
      price += districtCoefficient;

      return price;
    },
  },
};
</script>

<style scoped>
.slider {
  padding-top: 20px;
}
.input {
  width: 100px;
}
.house-type-span {
  color: aliceblue;
  padding-right: 5px;
}
.PricePredict {
  margin-top: 10px;
  margin-bottom: 100px;
}
.title {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  font-size: 28px;
  color: #f0c835;
}
.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  max-width: 1000px;
  min-width: 800px;
  border-radius: 20px;
  padding: 20px;
  background: #023037d7;
  box-shadow: 0 2px 5px 2px #023037;
  text-shadow: 1px 1px #f0c735b7;
}
.iconfont {
  font-size: 24px;
  font-weight: 400;
  padding-right: 5px;
}
</style>
