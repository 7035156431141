<template>
  <div id="app">
    <div id="toggleButton">
      <button id="button1" @click="toComponent(1)">Predict</button>
      <button id="button2" @click="toComponent(2)">Distribution Map</button>
      <button id="button2" @click="toComponent(3)">Cluster Map</button>
      <button id="button2" @click="toComponent(4)">Heat Map</button>
    </div>
    <PricePredict :class="{ 'hide-component': isPredict }" />
    <DistributionMap :class="{ 'hide-component': isDistribution }" />
    <ClusterMap :class="{ 'hide-component': isCluster }" />
    <HeatMap :class="{ 'hide-component': isHeat }" />
  </div>
</template>

<script>
import PricePredict from "./components/PricePredict.vue";
import DistributionMap from "./components/DistributionMap.vue";
import ClusterMap from "./components/ClusterMap.vue";
import HeatMap from "./components/HeatMap.vue";
export default {
  name: "App",
  components: {
    PricePredict,
    DistributionMap,
    ClusterMap,
    HeatMap,
  },
  data() {
    return {
      isPredict: false,
      isDistribution: true,
      isCluster: true,
      isHeat: true,
    };
  },
  methods: {
    toComponent(index) {
      if (index === 1) {
        this.isPredict = false;
        this.isDistribution = true;
        this.isCluster = true;
        this.isHeat = true;
      } else if (index === 2) {
        this.isPredict = true;
        this.isDistribution = false;
        this.isCluster = true;
        this.isHeat = true;
      } else if (index === 3) {
        this.isPredict = true;
        this.isDistribution = true;
        this.isCluster = false;
        this.isHeat = true;
      } else if (index === 4) {
        this.isPredict = true;
        this.isDistribution = true;
        this.isCluster = true;
        this.isHeat = false;
      }
    },
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;
}
.hide-component {
  display: none;
}
#toggleButton {
  width: 180px;
  border-radius: 0px 30px 30px 0px;
  background-color: #7575756b;
  padding: 10px;
  position: absolute; /* Position the container in the top-left corner of the screen */
  top: 10px;
  left: 0px;
  display: flex; /* Use flex to organize the buttons horizontally */
  flex-direction: column;
  gap: 5px; /* Set a gap of 10px between the buttons */
}

#button1,
#button2 {
  width: 120px;
  border-radius: 10px;
  padding: 10px; /* Add padding to the buttons */
  border: none; /* Remove border */
  background-color: #023037; /* Set background color */
  color: #f0c735; /* Set text color */
  cursor: pointer; /* Change cursor to pointer when hovering over the buttons */
  transition: background-color 0.3s; /* Add transition for button hover effect */
}

#button1:hover,
#button2:hover {
  color: #fff;
  background-color: #f0c735; /* Change background color when hovering over the button */
}

#app {
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("./assets/bj.png");
}
</style>
